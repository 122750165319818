import React, {useState} from 'react';
import { fluid_details } from './data/db_fluidDetails';
import Menu from "./Composants/Menu.js";
import Nos_activites from "./Composants/Nos_activites.js";
import Nous_contacter from "./Composants/Nous_contactes";
import Acceuil from "./Composants/Acceuil.js";
import Menu_a_propos from "./Composants/Menu_a_propos.js";
import A_propos from "./Composants/A_propos.js";
import Footer from "./Composants/Footer.js";

import fluidLogo from './img/logo.png';

function App() {
	
  const [etat_acceuil, setEtatAcceuil] =  useState(true);
  const [etat_nosActivites, setEtatNosActivites] =  useState(false);
  const [etat_aPropos, setEtatApropos] = useState(false);
  const [etat_nousContacter, setEtatNousContacter] = useState(false);
	
 const [id_menue, setId_menu_click] = useState(0);
  
  
    const evenement_click = (e) => {

	switch(e.target.firstChild.data){
			case "Acceuil": setEtatAcceuil(true);
			setEtatNosActivites(false);
			setEtatApropos(false);
			setEtatNousContacter(false);
			break;
			
			case "Nos activités": setEtatAcceuil(false);
			setEtatNosActivites(true);
			setEtatApropos(false);
			setEtatNousContacter(false);
			break;
			
			case " A propos": 
			setEtatAcceuil(false);
			setEtatNosActivites(false);
			setEtatApropos(true);
			setEtatNousContacter(false);
			
			break;
			
			case "Nous contacter": setEtatAcceuil(false);
			setEtatNosActivites(false);
			setEtatApropos(false);
			setEtatNousContacter(true);
			break;
  }
  

	  
  }
 
 
	 const check_menu_section_a_propos = (nouveau_valeur) => {
	  setId_menu_click(nouveau_valeur);
  }
 
 
 
 
 
  return (	
				<div>
						<div className="baniere">
							<div className="logo">
								<img src={fluidLogo} alt="Logo FluidEvent" />
							</div>
							<div className="nindo"><p>RECYCLAGE ET VALORISATION DES DECHETS</p></div>
						</div>
					
					
						<Menu evenement_click={evenement_click} />
					
							<div className="acceuil">
							
								{etat_acceuil && <Acceuil />}
								{etat_nosActivites && <Nos_activites /> }
								{etat_nousContacter && <Nous_contacter /> }
								
								<div className="menu_a_propos">
									<nav className="navbar navbar-light bg-light flex-column align-items-stretch p-3">
										{etat_aPropos && <div className="menu_a_prop"><div>  < Menu_a_propos check_menu_section_a_propos={check_menu_section_a_propos} /> 
															</div>
															<div className="nif_stat">
																SITUATION JURIDIQUE 
																<ul>
																	<li>STAT : 74330 31 2012 0 00776</li>
																	<li>NIF: 5000 949 467</li>
																	<li>RCS : 2012 B 00103</li>
																</ul>
															</div>
														</div>
										}
										
									</nav>
									<div>
										{etat_aPropos && < A_propos id_menue={id_menue} />}
									</div>
						
								</div>
							</div>
						<div>
							<Footer />
						</div>
				</div>
				
  );
  
}

export default App;
