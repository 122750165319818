import React, {useState} from 'react';
import { fluid_details } from '../data/db_fluidDetails';

const  A_propos = ({id_menue}) => {

    return (  
       
	   <div>
			<h2>{fluid_details[id_menue].titre}</h2>
			<p>{fluid_details[id_menue].texte_details}</p>
		</div>
    );

}
 
export default A_propos;