/*
    On accède aux fonction affiche_a_propos & ctrl_etat via au destructeur.
    la fonction ctrl_etat se déclenchera automatiquement si on clique sur Acceuil/ Nos activités/ Nous contactés
    tansdis que la fonction affiche_a_propos va se déclenche lors d'un clique sur A propos
*/ 

const  Nous_contactes = () => {

    
    return (  
		<div className="contact">

                                      <div className="mail_tel">
                                           <h2 className="contact_petite_resolution">Téléphone</h2>
                                               <ul>
                                                   <li>+261 32 04 286 88</li>
                                                   <li>+261 34 86 750 88</li>
                                                </ul>

                                                <h2 className="contact_petite_resolution">Adresse Mail</h2>
                                                <ul>
                                                   <li>fluidevent@fluidevent.mg</li>
                                                   <li>fluidevent.fluidevent@gmail.com</li>
                                                </ul>
                                       </div>

                                       <div className="adress">
                                            <ul>
	                                             <h2 className="contact_petite_resolution">Adresse siège </h2>
                                        	    <li>Lot I 155 Parcèlle 12/21-22 Analakininina Toamasina 501</li>
                                            </ul>

                                            <ul> 
                                            <h2 className="contact_petite_resolution">Adresse bureau Annexe </h2>
	                                              <li>Lot I Andranonanahary Anjomakely Antananarivo 101</li>
                                            </ul>

                                            <ul>
                                            <h2 className="contact_petite_resolution">Centre de traitement</h2>
	                                              <li>Centre de traitement de base GRT Toamasina</li>
	                                              <li>Centre de traitement de base Andranonanahary</li>
                                            </ul>
                                       </div>

                                   </div>
    );
}
 
export default Nous_contactes;