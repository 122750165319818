import React, {useState} from 'react';
import { fluid_details } from '../data/db_fluidDetails';

const  Menu_a_propos = ({check_menu_section_a_propos}) => {
	
	const tralala = (e) => {
		if(e){
			switch(e.target.id){
				case "EDITORIAL" :
					check_menu_section_a_propos(0);
				break;
				
				case "POLITIQUE ENVIRONNEMENTALE":
					check_menu_section_a_propos(1);
				break;
				
				case "RECYCLER LES DECHETS":
					check_menu_section_a_propos(2);
				break;
				
				case "Fluid Event UN PARTENAIRE RECONNU":
					check_menu_section_a_propos(3);
				break;
				
				case "DESCRIPTION GENERAL DU PROJET":
					check_menu_section_a_propos(4);
				break;
			}
		}
		
	}
		
    return (  
		fluid_details.map((donne_recu) => ( 
												<div  key={donne_recu.id} onClick={tralala}> 
													<a id={donne_recu.titre} key={donne_recu.id} href={"#"+donne_recu.id}> {donne_recu.titre}</a> 
												</div>)
										  )
    );
}
 
export default Menu_a_propos;