
const  Nos_activites = () => {

    
    return (  
		<ul>
          <li>
            <h2>Activités : </h2>
            <ul>
              <li>Maintenance des appareils et équipements (Station-service – Consommateur pétrolier – Dépôt pétrolier)</li>
              <li>Travaux d’installation pétrolier (Station-service – Consommateur pétrolier –Dépôt pétrolier)</li>
              <li>Collecte et traitement de déchets d’hydrocarbure (Boues-slops-cartons souillées-papiers</li>
              <li>souillés – sables souillés-huiles vidanges-fond des bacs- déchets des séparateurs)</li>
              <li>Nettoyage et barèmage des cuves</li>
              <li>Nettoyage des séparateurs d’hydrocarbures</li>
              <li>Maintenance en froid et climatisation</li>
              <li>Maintenance préventive des groupes électrogènes</li>
              <li>Génie civile</li>
            </ul>
          </li>
          
          <li>
          <h2> Autres travaux: </h2>
            <ul>
              <li>Travaux de maintenance et nouvelles installations sur commande</li>
              <li>Travaux de traitement de déchets sur commande</li>
              <li>Travaux de nettoyage, barèmage des cuves et nettoyage, neutralisation des séparateurs sur commande</li>
              <li>Interventions inopinées sur diverses stations et consommateurs selon commande des clients</li>
            </ul>
          </li>
        </ul>
    );
}
 
export default Nos_activites;