export const fluid_details = [
		{
			titre : "EDITORIAL",
			texte_details: "Jusqu'à ce jour, notre système d'amélioration continue a été élargi et installé dans tous les secteurs de la société. Ceci a permis d'augmenter la qualité des produits de recyclage et de réduire les émissions tout en augmentant la capacité de notre centre de traitement. Le succès de cette démarche a été démontré par la satisfaction de notre Client GALANA RAFFINERIE TERMINAL et l'appréciation des Auditeurs ISO de ce dernier. C'est grâce à l'énergie et l'implication de nos collaborateurs, à la coopération de nos clients, au dialogue avec les autorités et les organismes et associations de protection de l'environnement et enfin, grâce aux investissements importants que le Centre de traitement et de recyclage des boues et sols pollués en hydrocarbure ne cesse de se développer.",
			etat_details: true,
			id: 0
		},
		
		{
			titre: "POLITIQUE ENVIRONNEMENTALE",
			texte_details : 	"La mission de Fluid Event est produire des composts destiné aux reboisements en transformant les déchets (boues et sols pollués) provenant des fonds de bac de GRT grâce au procéder de recyclage BIO VENTILATIONLe service de recyclage offert par Fluid Event permet aux utilisateurs (ONG, association, entreprises, particulier ...) d'améliorer le rendement de leur activité de reboisement par la croissance rapide des végétations.Fluid Event n'hésite pas à développer et à mettre en œuvre des nouvelles technologies pour pouvoir améliorer le produit fini.",
			etat_details : false,
			id: 1
		},
		
		{
			titre : "RECYCLER LES DECHETS",
			texte_details : "Le procédé BIO VENTILATION consiste à injecter de l'air dans l'andin à travers des canalisation, ce qui fournit l'oxygène nécessaire à la biodégradation des contaminants par les microorganismes présents dans le sol. Des nutriments sont ajouter à l'injection afin de favoriser l'activité de ces microorganismes. La bio ventilation est particulièrement efficace dans le cas de traitement boue et sols pollués en hydrocarbure (produit blanc)",
			etat_details : false,
			id: 2
		},
		
		{
			titre: "Fluid Event UN PARTENAIRE RECONNU",
			texte_details : "Parce que trouver de nouvelles solutions de recyclage est notre objectif quotidien. Contactez-nous pour tout partenariat. Notre permis environnemental: 06/13-MEF/ONE/DG/PE du 17 avril 2013",
			etat_details : false,
			id: 3
		},
		
		{
			titre : "DESCRIPTION GENERAL DU PROJET",
 			texte_details : " La société GALANA RAFFINERIE TERMINAL a lancé un appel d’offre en Août 2012 pour la gestion de son centre de traitement des sols ou boues pollués en hydrocarbures. Les sols et boues proviennent des activités de nettoyage réservoirs de stockage ou pollution accidentelle. Le principal élément de l’appel d’offre était : 2500 m3 de déchets par an à traiter. Les opérations exigées du cahier de charge : traitement des sols et boues pollués en hydrocarbure par méthode biologique pour éviter toute pollution.  Procédé imposé : mode de traitement biologique, recyclage des déchets. L’offre de la société Fluid Event a été retenue en raison des points suivants : *Société spécialisée dans la maintenance et travaux pétrolier  *Recyclage et valorisation des déchets hydrocarbures et dérivés *Représentant de la société REP (Recherche Exploitation Produit)Le centre est implanté sur le terrain appartenant à GRT dénommé au temps de la SOLIMA centre de bitume et jouxte le terrain sur lequel une sphère de stockage de GPL existe mais dont cette dernière a été neutralisée selon information de GRT.",
			etat_details : false,
			id: 4
		}
	]