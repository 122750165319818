/*
    On accède aux fonction affiche_a_propos & ctrl_etat via au destructeur.
    la fonction ctrl_etat se déclenchera automatiquement si on clique sur Acceuil/ Nos activités/ Nous contactés
    tansdis que la fonction affiche_a_propos va se déclenche lors d'un clique sur A propos
*/ 

const  Menu = ({evenement_click}) => {

    
    return (  
        <nav className="menu">
            <div onClick={evenement_click}>Acceuil</div>
            <div onClick={evenement_click}>Nos activités</div>
            <div onClick={evenement_click}> A propos</div>
            <div onClick={evenement_click}>Nous contacter</div>
        </nav>
    );
}
 
export default Menu;