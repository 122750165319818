import responsable_du_centre from '../img/responsable_du_centre.png';
import personnel_du_centre   from '../img/personnel_du_centre.png';
import aire_de_stockage      from '../img/stockage.png';
import batiment_exploitation from '../img/batiment_exploitation.png';

import plateforme_de_traitement from '../img/plateforme_de_traitement.png';
import point_de_raliement from '../img/point_de_raliement.png';
import pose_de_canalisation_de_ventilation_andin from '../img/pose_de_canalisation_de_ventilation_andin.png';
import reglementation_acces_sur_la_dalle from "../img/reglementation_acces_sur_la_dalle.png";
import retournement_andin from '../img/retournement_andin.png';
import separateur_hydrocarbure from "../img/separateur_hydrocarbure.png";
import systeme_DCI_du_centre from "../img/systeme_DCI_du_centre.png";
import ventilisation_andin from "../img/ventilisation_andin.png";


const  Footer = () => {

    return (  
		<div className="footer">
			<p className="img_texte"> <strong>Fluidevent</strong> en quelques images</p>
			<div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" >
				<div className="carousel-indicators">
					<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
					<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
					<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
					<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 3"></button>
					<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 4"></button>
					<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 5"></button>
					<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 6"></button>
					<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="7" aria-label="Slide 7"></button>
					<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="8" aria-label="Slide 9"></button>
					<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="9" aria-label="Slide 9"></button>
					<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="10" aria-label="Slide 10"></button>
					<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="11" aria-label="Slide 11"></button>
				</div>

				<div className="carousel-inner">
					<div className="carousel-item active">
						<img src={responsable_du_centre} className="d-block w-100" alt="" />
						<div className="carousel-caption d-none d-md-block">
							<h5>Responsable du centre</h5>
						</div>
					</div>

					<div className="carousel-item">
						<img src={personnel_du_centre} className="d-block w-100" alt="..." />
						<div className="carousel-caption d-none d-md-block">
							<h5>Personnel du Centre</h5>
						</div>
					</div>


					<div className="carousel-item">
						<img src={batiment_exploitation} className="d-block w-100" alt="..." />
						<div className="carousel-caption d-none d-md-block">
							<h5>Batiment d'exploitation</h5>
						</div>
					</div>

					<div className="carousel-item">
						<img src={aire_de_stockage} className="d-block w-100" alt="..." />
						<div className="carousel-caption d-none d-md-block">
							<h5>Aire de stockage</h5>
						</div>
					</div>
					
					
					<div className="carousel-item">
						<img src={plateforme_de_traitement} className="d-block w-100" alt="..." />
							<div className="carousel-caption d-none d-md-block">
								<h5>Plateforme de traitement</h5>
							</div>
					</div>
	
	
					<div className="carousel-item">
						<img src={ point_de_raliement} className="d-block w-100" alt="..." />
						<div className="carousel-caption d-none d-md-block">
							<h5>Point de raliement</h5>
						</div>
					</div>
	
					<div className="carousel-item">
						<img src={pose_de_canalisation_de_ventilation_andin} className="d-block w-100" alt="..." />
							<div className="carousel-caption d-none d-md-block">
								<h5>Pose de canalisation de ventilation andin</h5>
							</div>
					</div>
	 
	 
					<div className="carousel-item">
						<img src={reglementation_acces_sur_la_dalle} className="d-block w-100" alt="..." />
						<div className="carousel-caption d-none d-md-block">
							<h5>Reglementation acces sur la dalle</h5>
						</div>
					</div>
	
					<div className="carousel-item">
						<img src={retournement_andin} className="d-block w-100" alt="..." />
						<div className="carousel-caption d-none d-md-block">
							<h5>Retournement andin</h5>
						</div>
					</div>
	
	
					<div className="carousel-item">
						<img src={separateur_hydrocarbure} className="d-block w-100" alt="..." />
						<div className="carousel-caption d-none d-md-block">
							<h5>Separateur hydrocarbure</h5>
						</div>
					</div>
	
					<div className="carousel-item">
						<img src={systeme_DCI_du_centre} className="d-block w-100" alt="..." />
						<div className="carousel-caption d-none d-md-block">
							<h5>systeme DCI du centre</h5>
						</div>
					</div>
	
					<div className="carousel-item">
						<img src={ventilisation_andin} className="d-block w-100" alt="..." />
						<div className="carousel-caption d-none d-md-block">
							<h5>Ventilisation andin</h5>
						</div>
					</div>	
				</div>
 
				<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
					<span className="carousel-control-prev-icon" aria-hidden="true"></span>
					<span className="visually-hidden">Previous</span>
				</button>
  
				<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
					<span className="carousel-control-next-icon" aria-hidden="true"></span>
					<span className="visually-hidden">Next</span>
				</button>
			</div>
		</div>
    );
}
 
export default Footer;