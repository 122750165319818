/*
    On accède aux fonction affiche_a_propos & ctrl_etat via au destructeur.
    la fonction ctrl_etat se déclenchera automatiquement si on clique sur Acceuil/ Nos activités/ Nous contactés
    tansdis que la fonction affiche_a_propos va se déclenche lors d'un clique sur A propos
*/ 

const  Acceuil = ({evenement_click}) => {

    
    return (  
<div>	
						<p>
							La société <strong>FLUID EVENT</strong> est une société pétrolière privée qui Oeuvre dans la
							Maintenance des appareils et équipements aussi bien dans les <em>stations-services</em>
							pétrolières que dans les consommateurs des compagnies <strong>pétrolières</strong> existant à
							Madagascar.
						</p>

						<ul>
							Elle exerce aussi ses activités dans tous les domaines pétroliers tels que :
							<li>
								<strong>Travaux</strong> d’ <strong>installation</strong> pétroliers : tuyauteries – électricités – plomberies
							</li>
							<li>
								<strong>Traitement</strong> des déchets <strong>d’hydrocarbures</strong> ou souillés par <em>l’hydrocarbure</em>		
							</li>
							<li>
								<strong>Maintenance</strong> des appareils <strong>frigorifiques</strong> et de <strong>climatisation</strong>
							</li>
						</ul>
						<p>
							Par ailleurs, en guise de mesure d’accompagnement, <strong>FLUID</strong> <strong>EVENT</strong> adopte un
							système de Management en conformité avec le SMSST actuel, et un objectif principal
							zéro accident ce qui sont ses atouts en termes de confiance vis-à-vis de ses clients et
							partenaires.
						</p>
					</div>
    );
}
 
export default Acceuil;